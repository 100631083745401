import { Form, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import IconDelete from '../../../../components/icons/Delete';
import IconSave from '../../../../components/icons/Save';
import containerData from '../../../../objects/containers/datas.json';
import {
  containersDelete as deleteMutation,
  containersUpdate as UpdateMutation,
} from '../../../../objects/containers/mutations';
import containerQuery from '../../../../objects/containers/queries';
import ContainerType from '../../../../types/Container';
import requiredAuth from '../../../../utils/requireAuth';

const ContainerPage: FC<PageProps> = ({ params: { id } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formName = 'container';
  const [deleteMutationFunc] = useMutation(deleteMutation);
  const [updateMutationFunc] = useMutation(UpdateMutation);
  const { data, error, loading, refetch } = useQuery(containerQuery, {
    variables: {
      id,
    },
  });

  const container: ContainerType = data && (data.container as ContainerType);

  if (loading) {
    return <CircularProgress fullPage />;
  }

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleOnSubmit = async (values: any) => {
    await updateMutationFunc({
      variables: {
        id,
        depth:
          '' !== values.depth && !Number.isNaN(Number(values.depth))
            ? Number(values.depth)
            : undefined,
        hasPallet: Boolean(values.hasPallet),
        height:
          '' !== values.height && !Number.isNaN(Number(values.height))
            ? Number(values.height)
            : undefined,
        isBio: Boolean(values.isBio),
        isPacked: Boolean(values.isPacked),
        isRefrigerated: Boolean(values.isRefrigerated),
        isStackable: Boolean(values.isStackable),
        name: values.name,
        quantity:
          '' !== values.quantity && !Number.isNaN(Number(values.quantity))
            ? parseInt(values.quantity, 10)
            : undefined,
        temperature:
          '' !== values.temperature && !Number.isNaN(Number(values.temperature))
            ? Number(values.temperature)
            : undefined,
        type: values.type,
        weight:
          '' !== values.weight && !Number.isNaN(Number(values.weight))
            ? Number(values.weight)
            : undefined,
        width:
          '' !== values.width && !Number.isNaN(Number(values.width))
            ? Number(values.width)
            : undefined,
      },
    });

    refetch();
  };

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const containerId = event.currentTarget.getAttribute('data-container');

    if (containerId) {
      const result = await deleteMutationFunc({
        variables: {
          id: containerId,
        },
      });

      if (!result || result.errors) {
        console.error(result.errors);
      } else {
        window.history.back();
      }
    }
  };

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  if (!container) {
    return <div>{t('container.update.messages.noContainer')}</div>;
  }

  return (
    <GridSplit
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
              title={t('container.actions.update')}
            />
            <Button
              datas={{
                'data-container': container.id,
              }}
              iconLeft={IconDelete}
              onClick={handleDeleteOnClick}
              size="small"
              status="danger"
              title={t('containers.update.actions.deleted')}
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: container.name,
      }}
    >
      {loading && <CircularProgress />}
      <Form
        className="mx-6 mb-6"
        datas={containerData}
        enableReinitialize
        hideSubmitButton
        initialValues={container}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: t(`container.form.submit.label`),
          size: 'small',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(ContainerPage, {
  admin: true,
  layout: true,
});
