import gql from 'graphql-tag';

const container = gql`
  query container($id: String!) {
    container(id: $id) {
      id
      depth
      hasPallet
      height
      isBio
      isRefrigerated
      isStackable
      isPacked
      name
      quantity
      temperature
      weight
      width
    }
  }
`;

export default container;
